.input-group {
  position: relative;
}
.rbt-aux {
  display: flex;
  position: absolute;
  top: 30%;
  right: 10px;
}
.rbt-close-content {
  display: none;
}
