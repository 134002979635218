.footer-image {
  max-width: 150px;
  max-height: 150px;
  min-height: 150px;
}
@media screen and (max-width: 760px) {
  .footer-image {
    max-width: 100px;
    max-height: 100px;
    min-height: 100px;
  }
}
