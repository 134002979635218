.day-month {
  width: 14.38%;
  min-height: 150px;
  position: relative;
}

.date-label {
  position: absolute;
  top: 3px;
  right: 5px;
}

.nextMonth {
  background-color: #6c757d;
  color: white;
}

.cart-button {
  right: 0;
}
