/* header {
  min-height: 100vh;
} */
header img {
  width: 100vw;
  height: 72vh;
  min-height: 72vh;
  object-fit: cover;
  filter: brightness(0.7);
}

header {
  position: relative;
}

section {
  position: absolute;
  top: 40%;
  left: 50%;
  min-width: 50vw;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

@media screen and (max-width: 760px) {
  section {
    min-width: 80vw;
  }
  section > h1 {
    font-size: 4rem !important;
    line-height: 3.5rem;
  }
  .rbt {
    width: 75% !important;
  }
}

.rbt {
  width: 91%;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.rbt > div > input {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.input-group-append > .btn {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
